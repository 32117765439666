import { hasNarrativeFeed } from "pages/DashboardPage/NarrativeFeed/utils";
import { ROUTE_PATHES } from "./constants";

export const navigateToPlatform = (url, newPlatform) => {
  const urlParts = url.split("/");

  const dashboardIndex = urlParts.indexOf("dashboard");
  const platformIndex = urlParts.indexOf("platform");

  if (platformIndex > -1) {
    urlParts[platformIndex + 1] = newPlatform;
  } else if (dashboardIndex > -1 && dashboardIndex < urlParts.length - 1) {
    urlParts.splice(dashboardIndex + 2, 0, "platform", newPlatform);
  }

  return urlParts.join("/");
};

export const navigateToNarrative = (url, urlSuffixToNavigateTo) => {
  const urlParts = url.split("/");

  const dashboardIndex = urlParts.indexOf("dashboard");
  const narrativeIndex = urlParts.indexOf("narrative");
  const platformIndex = urlParts.indexOf("platform");

  if (narrativeIndex > -1) {
    urlParts.splice(narrativeIndex, 2, urlSuffixToNavigateTo);
    return urlParts.join("/");
  } else if (platformIndex > -1) {
    urlParts.splice(platformIndex + 2, 0, urlSuffixToNavigateTo);
    return urlParts.join("/");
  } else if (dashboardIndex > -1) {
    urlParts.splice(dashboardIndex + 2, 0, urlSuffixToNavigateTo);
    return urlParts.join("/");
  } else {
    return `${url}/${urlSuffixToNavigateTo}`;
  }
};

export const getDefaultTargetPage = (flags) => {
  if (hasNarrativeFeed(flags)) {
    return checkNarrativeFeedDetails()
      ? ROUTE_PATHES.narrativeFeedDetails
      : ROUTE_PATHES.narrativeFeed;
  }
  return ROUTE_PATHES.overview;
};

export const checkNarrativeFeedDetails = () => {
  const regex = /\/narrativeFeed(\/.*)?\/details$/;
  return regex.test(window.location.pathname);
};

/**
 * Generates a page URL based on the provided project name, platform ID, narrative ID, target, and flags.
 *
 * @param {string} projectName - The name of the project.
 * @param {string} platformId - The ID of the platform.
 * @param {string} narrativeId - The ID of the narrative.
 * @param {string} target - The target page.
 * @param {object} flags - The flags object.
 * @return {string} The generated page URL.
 */
export const getPageUrl = (projectName, platformId, narrativeId, target, flags) => {
  const url = ["/dashboard"];

  if (!projectName) {
    return url[0];
  }

  const urlParts = window.location.pathname.split("/").filter((d) => d);
  const lastItem = urlParts[urlParts.length - 1];
  const validRoutes = Object.values(ROUTE_PATHES);

  // Detect target from url
  if (!target) {
    target = urlParts.length > 2 && validRoutes.includes(lastItem) ? lastItem : getDefaultTargetPage(flags);
  }

  url.push(projectName);

  const isNarrativeFeed = target === ROUTE_PATHES.narrativeFeed;
  const isNarrativeFeedDetails =
    checkNarrativeFeedDetails() && target === ROUTE_PATHES.narrativeFeedDetails;

  // For narrative details page, find narrativeId from route pathname
  if (isNarrativeFeedDetails) {
    const narrativeId = urlParts[urlParts.length - 2];
    url.push(`${ROUTE_PATHES.narrativeFeed}/${narrativeId}`);
  }

  // Narrative feed does not use platform and narrative
  if (platformId && !isNarrativeFeed && !isNarrativeFeedDetails) {
    url.push("platform/" + platformId);
    url.push("narrative/" + narrativeId || 0);
  }

  url.push(target);

  return url.join("/");
};

export const navigateToFeedDetails = (projectName, narrativeId) => {
  const url = [
    "/dashboard",
    projectName,
    ROUTE_PATHES.narrativeFeed,
    narrativeId,
    ROUTE_PATHES.narrativeFeedDetails,
  ];

  return url.join("/");
};

export const navigateToFeed = (projectName) => {
  const url = ["/dashboard", projectName, ROUTE_PATHES.narrativeFeed];
  return url.join("/");
};
