import moment from "moment";
import { NARRATIVE_FEED_KEY } from "utils/constants";
import { refineDisplayName, timezoneMap } from "utils/format";

export const refineName = (d) => {
  if (d.indexOf("user-cohorts_") === -1) {
    return refineDisplayName(d);
  }
  return d.replace("user-cohorts_", "");
};

export const cleanNarrativeId = (narrative_id) => {
  return (narrative_id || "").replaceAll('"', "").replace("narrative-", "");
};

export const getDataTestId = (text) => {
  return text.trim().replaceAll(" ", "_").replaceAll("-", "_");
};

export const CustomTick = (props) => {
  const { x, y, payload, data } = props;
  const text = payload.value || "";
  const datum = data.find((d) => d.name === text);

  return (
    <g transform={`translate(${x - 10},${y})`}>
      <text
        x={0}
        y={-10}
        dy={"0.32em"}
        textAnchor="end"
        fontSize={14}
        fill="#AFAFAF"
      >
        {text}
      </text>
      <text
        x={0}
        y={10}
        dy={"0.32em"}
        textAnchor="end"
        fontSize={12}
        fill="#6F7494"
      >
        {datum?.value}
      </text>
    </g>
  );
};

export const RISK_COLORS = {
  low: "#28C76F",
  medium: "#F79847",
  high: "#FD9087",
};

export const COMPASS_RISK_LABELS = {
  negligible: "#28C76F",
  medium: "#F79847",
  high: "#FD9087",
};

export const RISK_DOT_STYLES = {
  low: {
    border: "1px solid #2BE7A0",
    background: "rgba(43, 231, 160, 0.70)",
    boxShadow: "0px 0px 6px #2BE7A0",
  },
  moderate: {
    border: "1px solid #F6B916",
    background: "rgba(246, 185, 22, 0.70)",
    boxShadow: "0px 0px 6px #F6B916",
  },
  high: {
    border: "1px solid #FD9087",
    background: "#FD5447",
    boxShadow: "0px 0px 6px #FD5447",
  },
};

export const hasNarrativeFeed = (flag) => {
  if(localStorage.getItem(NARRATIVE_FEED_KEY) === "true") {
    return true;
  }
  return flag.narrativeFeed;
};

/**
 * Formats the given date string for timeline display based on the provided timezone offset and format.
 *
 * @param {string} dateString - The date string to format.
 * @param {number} timezoneOffset - The timezone offset to adjust the date to.
 * @param {string} [format="MMM DD"] - The format of the output date string.
 * @returns {string} The formatted date string for timeline display.
 */
export const formatDateForTimeline = (dateString, timezoneOffset, format = "MMM DD") => {
  if (dateString) {
    // Get the timezone from the timezone map
    const timezoneStandardName = timezoneMap[timezoneOffset.toString()];

    // If timezoneStandardName is undefined, it means the offset is not in the list.
    if (!timezoneStandardName) {
      return "";
    }

    const formatText = moment
      .utc(dateString)
      .tz(timezoneStandardName)
      .format(format);
    return formatText || "";
  }
  return "";
};

export const randomNoRepeats = (array) => {
  return array.sort(() => 0.5 - Math.random());
};
