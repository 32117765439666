import {
  checkDataValidityForProject,
  getClassifierProfiles,
  getFiltersAPI,
  getHarmProfiles,
  getModelLabelsAPI,
  getLanguages,
  getNarratives,
  getPlatforms,
  getProject,
  getUserCohorts,
  getUserGroups,
} from "api/endpoints/AnalyticsApi";
import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getPageUrl } from "utils/navigate";
import { getCancelToken } from "api/endpoints/utils";
import {
  DEFAULT_CLASSIFIER,
  DEFAULT_PROFILE,
  EXCLUDED_COHORTS,
  LANGUAGES_LIMIT,
  PARENT_USERS,
  sortPlatforms,
} from "utils/constants";
import {
  resolveCohortDisplayName,
  formatCohortsElasticSearchField,
} from "utils/cohorts";
import { getProjects } from "api/endpoints/UploadApi";
import {
  getDefaultFilters,
  useFilters,
} from "pages/DashboardPage/Shared/RichTable/filterConfig";

export default function useAppData() {
  const { projectName, narrativeId, platformId } = useParams();
  const history = useHistory();
  const flags = useFlags();
  const allFilters = useFilters();
  const initialized = useRef(false);

  const {
    dispatch,
    state: {
      user,
      project,
      platform,
      dynamic_filters,
      harmClassifiers,
      harmProfiles,
    },
  } = useAppContext();

  // Set harmClassifier and harmProfile in store
  useEffect(() => {
    if (user) {
      // select harm classifier
      const classifier =
        harmClassifiers.find(
          (hp) => hp.id === parseInt(user.classifierProfileRefString)
        ) || project.classifierProfile;

      if (classifier) {
        dispatch({
          type: ACTIONS.SET_HARM_CLASSIFIER,
          payload: classifier,
        });
      }

      // select harm profile
      const profile =
        harmProfiles.find(
          (hp) => hp.id === parseInt(user.harmProfileRefString)
        ) || project.harmProfile;

      if (profile) {
        dispatch({
          type: ACTIONS.SET_HARM_PROFILE,
          payload: profile,
        });
      }
    }
  }, [
    dispatch,
    user,
    project.classifierProfile,
    project.harmProfile,
    user.harmProfileRefString,
    user.classifierProfileRefString,
    harmClassifiers,
    harmProfiles,
  ]);

  // Load projects list
  useEffect(() => {
    const loadProjects = async () => {
      try {
        dispatch({
          type: ACTIONS.SET_PROJECTS_LOADING,
          payload: true,
        });

        const projectsResp = await getProjects();
        const projects = projectsResp.projects.filter((p) => p.dataAvailable);

        dispatch({
          type: ACTIONS.SET_PROJECTS,
          payload: projects,
        });

        // Check if project exists or user has access
        if (projectName && !projects.some((d) => d.name === projectName)) {
          return history.push(`/dashboard/${projectName}/not-found`);
        }

        // Find desired project
        const project = projectName
          ? projects.find((d) => d.name === projectName)
          : projects[0];

        // Redirect to default page
        if (project) {

          // Redirect to the default page
          return history.push(
            getPageUrl(
              project.name,
              undefined,
              undefined,
              undefined,
              flags
            )
          );
        }

        // Finally redirect to /projects-not-foun
        return history.push(`/projects-not-found/`);
      } catch (e) {
        console.error("loadProjects", e);
      } finally {
        dispatch({
          type: ACTIONS.SET_PROJECTS_LOADING,
          payload: false,
        });
      }
    };

    // Stopping double load
    if (!initialized.current) {
      initialized.current = true;
      loadProjects();
    }

    return () => {
      dispatch({
        type: ACTIONS.SET_PROJECTS_LOADING,
        payload: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // Load user groups
  useEffect(() => {
    const gerUserGroups = async () => {
      let groups = [];
      if (project.id) {
        groups = await getUserGroups(project.id);
      }
      if (groups) {
        dispatch({
          type: ACTIONS.SET_USER_GROUPS,
          payload: groups,
        });
      }
    };
    gerUserGroups();
  }, [dispatch, project.id]);

  // get classifier profiles and preselect harmClassifierProfile
  useEffect(() => {
    const getProfiles = async () => {
      const harmClassifiers = await getClassifierProfiles();

      if (harmClassifiers && harmClassifiers.length) {
        const arr = [DEFAULT_CLASSIFIER];

        if (
          project.classifierProfile &&
          !harmClassifiers.some((d) => d.id === project.classifierProfile.id)
        ) {
          arr.push({
            isGlobal: true,
            ...project.classifierProfile,
          });
        }

        const completeHarmProfiles = [
          ...arr,
          ...harmClassifiers.map((d) => {
            return {
              ...d,
              isGlobal: d.id === project.classifierProfile?.id,
            };
          }),
        ];

        dispatch({
          type: ACTIONS.SET_HARM_CLASSIFIERS,
          payload: completeHarmProfiles,
        });
      }
    };
    getProfiles();
  }, [dispatch, project.classifierProfile]);

  // get harm profiles
  useEffect(() => {
    const getProfiles = async () => {
      const harmProfiles = await getHarmProfiles(); // returns empty set for non bb users
      // blackbird user
      if (harmProfiles && harmProfiles.length) {
        const arr = [DEFAULT_PROFILE];

        // if project harm profile exists and custom harm profiles do not contain that profile, add it to the list
        if (
          project.harmProfile &&
          !harmProfiles.some((d) => d.id === project.harmProfile.id)
        ) {
          arr.push({
            isGlobal: true,
            ...project.harmProfile,
          });
        }

        const completeHarmProfiles = [
          ...arr,
          ...harmProfiles.map((d) => {
            return {
              ...d,
              isGlobal: d.id === project.harmProfile?.id,
            };
          }),
        ];

        dispatch({
          type: ACTIONS.SET_HARM_PROFILES,
          payload: completeHarmProfiles,
        });
      }
    };
    getProfiles();
  }, [dispatch, project.harmProfile]);

  // Load and set unsupported features
  useEffect(() => {
    const checkDataValidity = async () => {
      if (projectName) {
        const resp = await checkDataValidityForProject(projectName, {
          fieldName: "parents",
          fieldType: "nested",
        });
        dispatch({
          type: ACTIONS.SET_UNSUPPORTED_FEATURE,
          payload: { featureName: PARENT_USERS, supported: resp },
        });
      }
    };
    checkDataValidity();
  }, [projectName, dispatch]);

  // Load dynamic filters
  useEffect(() => {
    if (platform) {
      getFiltersAPI(platform).then((data) => {
        dispatch({
          type: ACTIONS.SET_PROJECT_DYNAMIC_FILTERS,
          payload: data ? data : [],
        });
      });
    }
  }, [dispatch, platform]);

  // Load languages
  useEffect(() => {
    const loadLanguages = async () => {
      const langByPlatform = await getLanguages(project.name);
      const languages = {};

      Object.keys(langByPlatform).forEach((platform) => {
        const arr = langByPlatform[platform];
        const sum = arr.reduce((s, d) => s + d.post_ct, 0) || 1;
        languages[platform] = arr.filter(
          (d) => d.post_ct / sum > LANGUAGES_LIMIT
        );
      });

      dispatch({
        type: ACTIONS.SET_LANGUAGES,
        payload: languages,
      });
    };
    if (project && project.name && platform && flags.multilingual) {
      loadLanguages();
    }
  }, [dispatch, project, platform, flags.multilingual]);

  const cancelToken = useRef();

  const getToken = () => {
    if (cancelToken.current) {
      cancelToken.current.cancel();
    }
    cancelToken.current = getCancelToken();
    return { cancelToken: cancelToken.current.token };
  };

  // Load Project object, Narratives and Platforms
  useEffect(() => {
    const loadAll = async () => {
      const config = getToken();

      try {
        // start data loading
        dispatch({
          type: ACTIONS.SET_DATA_LOADING,
          payload: true,
        });

        // load concurently
        const [project, platforms, narratives] = await Promise.all([
          getProject(projectName, config),
          getPlatforms(projectName, config),
          getNarratives(projectName, config),
        ]);

        // finish data loading
        dispatch({
          type: ACTIONS.SET_DATA_LOADING,
          payload: false,
        });

        // Project
        dispatch({
          type: ACTIONS.SELECT_PROJECT,
          payload: project || {},
        });

        // Platforms
        const platformsList = platforms.sort((a, b) => {
          return sortPlatforms(a.name, b.name);
        });

        const platformName = platformId || platformsList[0]?.name;

        dispatch({
          type: ACTIONS.SET_PLATFORMS,
          payload: platformsList,
        });

        dispatch({
          type: ACTIONS.SET_PLATFORM,
          payload: platformName,
        });

        // Narratives
        const narrativeIndex = Math.max(parseInt(narrativeId) || 0, 0);

        dispatch({
          type: ACTIONS.SET_LOADED_NARRATIVES,
          payload: narratives,
        });

        dispatch({
          type: ACTIONS.SELECT_NARRATIVE,
          payload: narrativeIndex,
        });

        const defaultNarrative = narratives.find((d) => d.name === "All");
        if (defaultNarrative) {
          dispatch({
            type: ACTIONS.SET_MIN_MAX_DATES,
            payload: {
              min_date: defaultNarrative.startDate,
              max_date: defaultNarrative.endDate,
            },
          });
        }

        const projName = project?.name;

        // update url when data finishes loading and selects everything
        if (
          projName !== undefined &&
          platformName !== undefined &&
          narrativeIndex !== undefined
        ) {
          // Redirect to the default page
          history.push(
            getPageUrl(
              projName,
              platformName,
              narrativeIndex,
              undefined,
              flags
            )
          );
        }
      } catch (e) {
        console.error("Could not load project data", e);

        dispatch({
          type: ACTIONS.SET_DATA_LOADING,
          payload: false,
        });

        // if requests were cancelled, do not show the error
        if (!e.__CANCEL__) {
          dispatch({
            type: ACTIONS.SHOW_MESSAGE,
            payload: {
              message: e.message,
              type: "error",
            },
          });
        }
      }
    };

    if (projectName) {
      loadAll();
    }

    return () => {
      if (cancelToken.current) {
        cancelToken.current.cancel();
      }

      // Important, enables useApi function
      dispatch({
        type: ACTIONS.SET_DATA_LOADING,
        payload: false,
      });

      dispatch({
        type: ACTIONS.SHOW_MESSAGE,
        payload: null,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectName, dispatch]);

  // Load cohorts
  useEffect(() => {
    const loadCohorts = async () => {
      try {
        const [modelLabels, apiUserCohorts] = await Promise.all([
          getModelLabelsAPI(),
          // getLabels(projectName, "analysis.cohorts"),
          getUserCohorts(projectName),
        ]);

        const userCohorts = (apiUserCohorts || [])
          .map((auc) => ({
            // user cohorts
            ...auc,
            fieldName: auc.elasticSearchField,
            isUserCohort: true,
          }))
          .map((cohort) => {
            const shortName = formatCohortsElasticSearchField(cohort.fieldName);
            const displayName =
              modelLabels[cohort.fieldName] ||
              cohort.externalName ||
              resolveCohortDisplayName(shortName);
            // to-do: make the cohort elastic search field api keys consistent
            const fullFieldName =
              (cohort.isUserCohort
                ? cohort.elasticSearchField
                : cohort.fieldName) + "_ct";

            return {
              ...cohort,
              description: "",
              shortName,
              displayName,
              fullFieldName,
            };
          })
          .filter((d) => !EXCLUDED_COHORTS.includes(d.shortName));

        // const topics = allCohorts.filter((d) => !d.isUserCohort);
        // const userCohorts = allCohorts.filter((d) => d.isUserCohort);

        dispatch({
          type: ACTIONS.SET_MODEL_LABELS,
          payload: modelLabels,
        });

        dispatch({
          type: ACTIONS.SET_COHORTS,
          payload: {
            cohortNames: [],
            cohortList: [],
            userCohorts,
          },
        });
      } catch (e) {
        console.error(e.message);
      }
    };

    if (projectName) {
      loadCohorts();
    }
  }, [projectName, dispatch]);

  // Set default filters
  useEffect(() => {
    const defaultFilters = user?.organization?.defaultFilters;
    if (dynamic_filters && defaultFilters) {
      dispatch({
        type: ACTIONS.UPDATE_DEFAULT_FILTER,
        payload: getDefaultFilters(defaultFilters || [], allFilters, platform),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, platform, dynamic_filters, user?.organization?.defaultFilters]);

  return null;
}
