import { AppBar as MuiAppBar, Skeleton, Box } from "@mui/material";
import { deleteNarrative } from "api/endpoints/AnalyticsApi";
import useEventTracker from "api/hooks/useEventTracker";
import CustomDropdown from "components/UI/CustomDropdown";
import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import useTabs from "hooks/useTabs";
import ProjectSettings from "pages/DashboardPage/Shared/Settings/ProjectSettings";
import { ReactComponent as SidebarProjectIcon } from "icons/UI/Sidebar-Project.svg";
import { useCallback, useMemo } from "react";
import {
  useHistory,
  useParams,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import {
  ALL_PROJECT,
  DRAWER_CLOSED_WIDTH,
  ROUTE_PATHES,
} from "utils/constants";
import { calculateTopicFolders } from "utils/format";
import {
  getPageUrl,
  navigateToNarrative,
  navigateToPlatform,
} from "utils/navigate";
import useProjectChange from "hooks/useProjectChange";
import UserInfo from "../UserProfile/UserInfo";
import useMessage from "hooks/useMessage";
import { styled } from "@mui/system";

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  width: `calc(100% - ${DRAWER_CLOSED_WIDTH}px)`,
  backgroundColor: theme.palette.background.$0,
  minHeight: 60,
  padding: 0,
  boxShadow: "none",
  borderBottom: "none",
  borderLeft: "none",
  zIndex: theme.zIndex.drawer + 1,
  backgroundImage: "none",
  top: 0,
}));

const TopNav = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.$1,
  borderBottom: "1px solid " + theme.palette.border.main,
  padding: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const TitleWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(1.25, 0),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Title = styled("div")({
  color: "#6F7494",
  fontSize: "10px",
  lineHeight: "15px",
  fontWeight: "normal",
  letterSpacing: "0.1em",
  textTransform: "uppercase",
});

const Crumb = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1.5, 0, 3),
  position: "relative",
  overflow: "visible",
  "&:nth-of-type(2)": {
    borderLeft: "1px solid #282C38",
  },
  "&:nth-of-type(3)": {
    paddingLeft: theme.spacing(1.5),
  },
}));

function TopBar({ onlyProjects }) {
  const tracker = useEventTracker();
  const projectChanger = useProjectChange();
  const { currentPath } = useTabs();
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const showMessage = useMessage();
  const history = useHistory();

  const { projectName, narrativeId } = useParams();
  const narrativeIndex = parseInt(narrativeId) || 0;
  const {
    dispatch,
    state: {
      user,
      projects,
      projectsLoading,
      platform,
      platforms,
      project,
      narratives,
      dataLoading,
    },
  } = useAppContext();

  const onPlatformChange = useCallback(
    (e) => {
      dispatch({
        type: ACTIONS.SET_PLATFORM,
        payload: e.target.value,
      });
      history.push(navigateToPlatform(pathname, e.target.value));
      tracker.track(
        "Changed platform",
        "select",
        e.target.value,
        "Top navbar / platform"
      );
    },
    [dispatch, history, tracker, pathname]
  );

  const narrative = narratives[narrativeIndex];
  const activeNarrativeName = narrative?.name ?? ALL_PROJECT;
  const narrativeNames = narratives.map(({ name }) => name);

  const handleSelectNarrative = (event) => {
    const index = narratives.findIndex(
      ({ name }) => name === event.target.value
    );
    if (index > -1) {
      if (currentPath === ROUTE_PATHES.narratives) {
        if (narratives[index].name === ALL_PROJECT) {
          history.push(navigateToNarrative(url, "narrative/0/discover"));
        } else {
          history.push(navigateToNarrative(url, `narrative/${index}/refine`));
        }
      } else {
        if (currentPath === ROUTE_PATHES.report) {
          history.push(navigateToNarrative(url, `narrative/${index}/report`));
        } else {
          history.push(navigateToNarrative(pathname, `narrative/${index}`));
        }
      }
      dispatch({
        type: ACTIONS.SELECT_NARRATIVE,
        payload: index,
      });
      tracker.track(
        "Selected narrative",
        "select",
        event.target.value,
        "Top bar / narrative"
      );
    }
  };

  const handleDeleteNarrative = async (narrativeName) => {
    const narrativeIndexToDelete = narratives.findIndex(
      ({ name }) => narrativeName === name
    );

    if (narrativeIndexToDelete > -1) {
      const nar = narratives[narrativeIndexToDelete];

      try {
        dispatch({
          type: ACTIONS.REMOVE_NARRATIVE,
          payload: narrativeIndexToDelete,
        });

        const newNarrativeIndex =
          narrativeIndexToDelete === narrativeIndex
            ? 0
            : narrativeIndex > narrativeIndexToDelete
            ? narrativeIndex - 1
            : narrativeIndex;

        history.push(
          navigateToNarrative(url, `narrative/${newNarrativeIndex}/overview`)
        );

        await deleteNarrative({
          narrativeId: nar.narrativeId,
          projectName: nar.projectName,
        });

        tracker.track(
          "Deleted narrative",
          "remove",
          nar.narrativeId,
          "Top navbar / narrative"
        );

        showMessage("Narrative deleted.", "info");
      } catch (e) {
        console.error(e.message);
        showMessage("Error when deleting narrative.", "error");
      }
    }
  };

  const launchCreateProject = () => {
    history.push(`/manage/projects/create`);
  };

  const launchCreateNarrative = () => {
    history.push(navigateToNarrative(url, "narrative/0/discover"));
  };

  const launchRefineNarrative = (narrativeName) => {
    const narrativeIndex = narratives.findIndex(
      ({ name }) => narrativeName === name
    );

    if (narrativeIndex > -1) {
      history.push(
        navigateToNarrative(url, `narrative/${narrativeIndex}/refine`)
      );
    }
  };

  const narrativeOptions = narrativeNames.map((d) => ({
    value: d,
    label: d,
    deletable: d !== ALL_PROJECT,
    editable: d !== ALL_PROJECT,
  }));

  const platformOptions = platforms.map((d) => ({
    value: d.value,
    label: d.name,
    disabled: d.disabled,
    isBeta: d.isBeta,
  }));

  const projectOptions = useMemo(() => {
    const arr = calculateTopicFolders(projects || []);
    return arr
      .map((d) =>
        d.projects.map((x, i) => ({
          label: x.displayName ? x.displayName : x.name,
          value: String(x.id),
          showGroup: i === 0,
          groupName: d.name,
        }))
      )
      .flat();
  }, [projects]);

  // Find projectName in the projects list
  const selectedProjectId = useMemo(() => {
    if (projectName && projects.length) {
      const proj = projects.find((d) => d.name === projectName);
      return proj?.id ? String(proj?.id) : null;
    }
    return null;
  }, [projectName, projects]);

  const projectsMap = useMemo(
    () => new Map(projects.map((d) => [d.id, d])),
    [projects]
  );

  const onProjectChange = (e) => {
    const selectedProject = +e.target.value;
    const p = projectsMap.get(selectedProject);
    if (p) {
      projectChanger.change(p.name);
      tracker.track("Changed project", "select", p.name, "Top Nav / project");

      let target = undefined;

      // If on narrative builder page, navigate to discover page
      if (currentPath === ROUTE_PATHES.narratives) {
        target = "discover";
      } 
      // Reset url to narrative feed on project change
      else if (currentPath === ROUTE_PATHES.narrativeFeed) {
        target = ROUTE_PATHES.narrativeFeed;
      }

      const pageUrl = getPageUrl(p.name, undefined, undefined, target);

      history.push(pageUrl);
    }
  };

  const skeleton = (
    <Skeleton
      variant="rectangular"
      height={20}
      sx={{ marginTop: "4px", borderRadius: "4px", color: "#242234" }}
    />
  );

  return (
    <AppBar position="fixed">
      <TopNav>
        <TitleWrapper>
          <Crumb>
            <Title>PROJECT</Title>
            {projectsLoading || dataLoading ? (
              skeleton
            ) : (
              <CustomDropdown
                compact
                transparent
                searchEnabled
                placeholder="..."
                value={
                  project?.id ? String(project?.id) : selectedProjectId || ""
                }
                options={projectOptions}
                onChange={onProjectChange}
                itemIcon={<SidebarProjectIcon />}
                onFooterClick={user?.uploadAccess ? launchCreateProject : null}
                footerText={user?.uploadAccess ? "Create a project" : null}
                id="Project_dropdown"
                dataTestId="Project_dropdown"
              />
            )}
          </Crumb>

          {!onlyProjects && (
            <Crumb>
              <Title>PLATFORM</Title>
              {dataLoading ? (
                skeleton
              ) : (
                <CustomDropdown
                  compact
                  value={platform || ""}
                  placeholder={"..."}
                  options={platformOptions}
                  onChange={onPlatformChange}
                  transparent
                  id="Platform_dropdown"
                  dataTestId="Platform_dropdown"
                />
              )}
            </Crumb>
          )}

          {!onlyProjects && (
            <Crumb>
              <Title>NARRATIVE</Title>
              {dataLoading ? (
                skeleton
              ) : (
                <CustomDropdown
                  compact
                  searchEnabled
                  value={activeNarrativeName}
                  options={narrativeOptions}
                  onEdit={launchRefineNarrative}
                  onFooterClick={launchCreateNarrative}
                  onDelete={handleDeleteNarrative}
                  onChange={handleSelectNarrative}
                  footerText={"Create a new narrative"}
                  transparent
                  id="Narrative_dropdown"
                  dataTestId="Narrative_dropdown"
                />
              )}
            </Crumb>
          )}
        </TitleWrapper>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ProjectSettings />
          <UserInfo />
        </Box>
      </TopNav>
    </AppBar>
  );
}

export default TopBar;
