import React, { useMemo, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { ascending, descending } from "d3-array";
import { Box, styled } from "@mui/system";

const StyledTableCell = styled(TableCell)(({ theme, ...props }) => ({
  borderBottom: "none",
  padding: "6px",
  "&.MuiTableCell-head": {
    color: theme.palette.text.light,
    fontStyle: "normal",
    fontSize: "12px",
    fontWeight: "400",
    padding: "8px 10px",
    lineHeight: "20px",
    textAlign: props.align || "left",
    borderBottom: "1px solid #282C38",
    textDecoration: "underline",
    textDecorationStyle: "dotted",
    whiteSpace: "nowrap",
    cursor: "pointer",
    backgroundColor: props.headerBackground || theme.palette.background.$1,
  },
  "&.MuiTableCell-body": {
    backgroundColor: "transparent",
    color: theme.palette.text.light,
    borderBottom: "1px solid #282C38",
    padding: "8px 10px",
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "20px",
    textAlign: props.align || "left",
  },
}));

const useStyles = ({ height, autoHeight, borderColor }) => ({
  table: {
    maxWidth: "100%",
    width: "100%",
    margin: 0,
    borderColor: borderColor,
  },
  paper: {
    background: "transparent",
    maxHeight: autoHeight ? "null" : height || "calc(100vh - 90px)",
    overflowY: "auto",
  },
  thContent: {
    display: "flex",
    alignItems: "center",
  },
  errorTd: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  error: {
    background: "rgba(235, 104, 104, 0.1)",
    textAlign: "center",
    color: "rgba(242, 133, 133, 0.8)",
    width: "100%",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "35px",
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      marginRight: 8,
    },
  },
});

const getValue = (col, d) => {
  if (col.field === "*") {
    return d;
  }

  let value = d[col.field];

  if (value === null || value === undefined) {
    return "-";
  }

  return typeof col.format === "function" ? col.format(value) : value;
};

export default function SimpleTable({
  data,
  columns,
  stickyHeader,
  height,
  defaultSort = "",
  headerBackground,
  autoHeight = false, // Lets the table auto layout the height instead of fixed
  borderColor = "#282C38", // Border color
}) {
  const styles = useStyles({ height, autoHeight, borderColor });

  const [sortDir, setSortDir] = useState("DESC");
  const [sortField, setSortField] = useState(defaultSort);

  const handleHeaderClick = (col) => {
    const sortingEnabled = col.sortingEnabled ?? true;
    if (sortingEnabled) {
      if (col.field === sortField) {
        setSortDir((v) => (v === "DESC" ? "ASC" : "DESC"));
      } else {
        setSortField(col.field);
        setSortDir("DESC");
      }
    }
  };

  const localData = useMemo(() => {
    return data.sort((a, b) => {
      if (sortDir === "ASC") {
        return ascending(a[sortField], b[sortField]);
      }
      return descending(a[sortField], b[sortField]);
    });
  }, [sortField, sortDir, data]);

  const getDataTestId = (col) => {
    let id = "";

    if (col.text && typeof col.text === "string") {
      id = col.text;
    } else if (col.field && typeof col.field === "string") {
      id = col.field;
    }

    return id.replace(/\s/g, "-").toLowerCase();
  };

  return (
    <TableContainer component={Paper} sx={styles.paper}>
      <Table sx={styles.table} stickyHeader={stickyHeader}>
        <TableHead>
          <TableRow>
            {columns.map((col, index) => {
              const active = sortField === col.field;
              const sortingEnabled = col.sortingEnabled ?? true;
              return (
                <StyledTableCell
                  key={index}
                  onClick={() => handleHeaderClick(col)}
                  align={col.align}
                  width={col.width}
                  headerBackground={headerBackground}
                  data-testid={`table-column-${getDataTestId(col)}`}
                >
                  <Box sx={styles.thContent}>
                    {col.text}
                    {sortingEnabled && (
                      <>
                        {sortDir === "ASC" && active ? (
                          <ExpandLessIcon fontSize="small" />
                        ) : sortDir === "DESC" && active ? (
                          <ExpandMoreIcon fontSize="small" />
                        ) : (
                          <UnfoldMoreIcon fontSize="small" />
                        )}
                      </>
                    )}
                  </Box>
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {localData.map((d, j) => {
            return (
              <TableRow key={j} data-testid={`table-row-${j}`}>
                {columns.map((col, i) => {
                  const Comp = col.cellComponent;
                  const value = getValue(col, d);

                  let content = null;

                  if (Comp) {
                    content = <Comp value={value} error={d.error} />;
                  } else {
                    content = value;
                  }

                  return (
                    <StyledTableCell
                      width={col.width}
                      align={col.align}
                      key={i}
                    >
                      {content}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
