import axios from "axios";
import api from "../axiosInstances/axiosDataConnectors.js";
import strapi from "../axiosInstances/axiosStrapi";
import axiosRetry from "axios-retry";
import { ascending } from "d3-array";
import {
  BB_DEFAULT_TWITTER_API,
  BB_DEFAULT_WEBZ_API,
  PROJECT_ACTIONS,
} from "pages/DataUploader/utils/constants.js";
import { JWT_TOKEN_FIELD } from "utils/constants.js";

const MAX_PART_SIZE = 5 * 1024 * 1024; // Maximum part size in bytes
axiosRetry(api, { retries: 0 });

export const uploadFilePartToS3 = async (
  file,
  partNumber,
  fileData,
  fileType
) => {
  try {
    const start = (partNumber - 1) * MAX_PART_SIZE;
    const end = Math.min(partNumber * MAX_PART_SIZE, fileData.size);
    const chunk = fileData.slice(start, end);

    const params = {
      url: file.presignedUrls[partNumber - 1], // Include partNumber in the query string
      method: "put",
      data: chunk, // Upload only the segment of the file corresponding to the part
      headers: {
        "Content-Type": fileType,
      },
      maxBodyLength: Infinity,
    };

    const response = await axios(params);

    if (response.status !== 200) {
      const responseBody = response.data;
      console.error(
        `Non-200 response from AWS: ${response.status}. Response body: ${responseBody}`
      );
      return null;
    } else {
      // Capture ETag from response headers
      const eTag = response.headers["etag"];
      if (!eTag) {
        console.error(
          "ETag header is null. Check CORS settings on S3 bucket to expose ETag header."
        );
        return null;
      }
      return eTag; // Return ETag for the completion step
    }
  } catch (error) {
    console.error("Error uploading file to S3:", error);
  }
};

export const completeMultipartUpload = async (file) => {
  const response = await api.post("/uploadSignedComplete", {
    uploadId: file.uploadId,
    bucketkey: file.bucketkey,
    parts: file.parts,
  });
  return response?.data;
};

/* Send an array of objects representing files to be uploaded to get a presigned URL
[{name,size,type}]
*/
export const getFileUploadSignedUrls = async (data) => {
  const response = await api.post("/uploadSigned", { files: data });
  return response?.data;
};

export const getDataConnectors = async ({ connectorName }) => {
  const response = await api.get(`/strapi/data-connectors/${connectorName}`, {
    clearCacheEntry: true,
  });
  return (response?.data?.data || [])
    .filter((d) => !d.disabled)
    .sort((a, b) => {
      if (a.name === BB_DEFAULT_TWITTER_API || a.name === BB_DEFAULT_WEBZ_API) {
        return -1;
      }

      if (b.name === BB_DEFAULT_TWITTER_API || a.name === BB_DEFAULT_WEBZ_API) {
        return 1;
      }

      return ascending(a.name, b.name);
    })
    .map((d) => ({
      ...d,
      value: d.id,
      label: d.name || d.id,
      highlighted:
        d.name === BB_DEFAULT_TWITTER_API || d.name === BB_DEFAULT_WEBZ_API,
    }));
};

export const getQueryCount = async (connectorName, payload, config) => {
  const url = `/data-connectors/${connectorName}/query-count`;
  const resp = await api.post(url, payload, config);
  return resp?.data?.body || null;
};

export const getDocumentCount = async (connectorName, payload, config) => {
  const url = `/data-connectors/${connectorName}/topic-count`;
  const resp = await api.post(url, payload, config);
  return resp?.data?.body || null;
};

export const addDataConnector = async (payload) => {
  const response = await api.post(`/data-connectors`, payload);
  return response.data || null;
};

export const getProjects = async (query) => {
  const { page, pageSize, projectIds, searchText, config } = query || {};
  const url = "/strapi/data-connector-projects";
  const payload = {
    page,
    pageSize,
    projectIds,
    searchText,
  };
  const token = localStorage.getItem(JWT_TOKEN_FIELD);
  const axiosStrapiConfig = {
    ...config,
    headers: {
      'Authorization': `Bearer ${token}`,
    },
    // Remove caching for get projects calls to avoid showing stale data.
    // Read more: https://www.npmjs.com/package/axios-cache-adapter#options
    cache: {
      ignoreCache: true,
    },
    "axios-retry": {
      retries: 2, // Retry two more times.
      retryCondition: (error) => {
        // If retry condition is not specified, by default idempotent requests are retried
        return error.response.status === 500;
      },
    },
  };
  const response = await api.post(url, payload, axiosStrapiConfig);

  const { data } = response;
  if (!data) throw new Error("Invalid results.");

  const { body } = data;
  if (!body) throw new Error("Invalid results.");

  return {
    projects: body,
    pageCount: data.pageCount || 1,
  };
};

export const addDataConnectorJob = async (payload) => {
  const response = await api.post(`/data-connector-jobs`, {
    data: payload,
  });
  return response.data || null;
};

export const runDataConnectorJob = async (id) => {
  const response = await api.get(`/data-connectors/run/${id}`);
  return response.data || null;
};

export const queueDataConnectorJob = async (jobPayload) => {
  const response = await api.post(`/data-connectors/queue`, jobPayload);
  return response.data || null;
};

export const cancelDataConnectorJob = async (id) => {
  const response = await api.get(`/data-connectors/cancel/${id}`);
  return response;
};

export const getDataConnectorProjects = async (connectorName, payload) => {
  try {
    const response = await api.post(
      `/data-connectors/${connectorName}/projects`,
      payload,
      { clearCacheEntry: true }
    );
    return (response.data.body || []).map((d) => ({
      label: d.name,
      value: d.id,
    }));
  } catch {
    return [];
  }
};

export const getDataConnectorFilters = async (
  connectorName,
  payload,
  config
) => {
  const url = `/data-connectors/${connectorName}/filters`;
  try {
    const response = await api.post(url, payload, config);
    return response?.data?.body || {};
  } catch {
    return {};
  }
};

export const getDataConnectorTopics = async (connectorName, payload) => {
  try {
    const response = await api.post(
      `/data-connectors/${connectorName}/topics`,
      payload,
      { clearCacheEntry: true }
    );
    return (response.data.body || []).map((d, i, arr) => ({
      ...d,
      showGroup: arr[i - 1]?.groupName !== d.groupName,
      label: d.name,
      value: d.id,
    }));
  } catch {
    return [];
  }
};

export const getProjectFolders = async (orgId) => {
  try {
    const res = await strapi.get(
      `/api/topics?populate=*&filters[organizations][id][$eq]=${orgId}`
    );

    return res.data;
  } catch {
    return [];
  }
};

export const addProjectFolder = async (payload) => {
  const res = await strapi.post(`/api/topics`, { data: payload });
  return res.data;
};

export const getOrgUsers = async (id, config) => {
  try {
    const response = await api.get(`/strapi/org-users/${id}`, config);
    return response.data || null;
  } catch {
    return null;
  }
};

export const updateProjectJobSchedule = async (projectId, payload) => {
  if (payload.jobSchedule) {
    payload.jobSchedule.env =
      process.env.REACT_APP_SCHEDULER_ENV || "development";
  }
  const res = await strapi.put(`/api/projects/${projectId}`, {
    data: payload,
  });
  return res.data;
};

export const addExportDataConnector = async (payload) => {
  try {
    const response = await api.post(`/export-data-connectors`, payload);
    return response.data || null;
  } catch (err) {
    const errorMsg = err?.response.data.error_msg || err.message;
    throw new Error(errorMsg);
  }
};

export const updateExportDataConnector = async (id, payload) => {
  try {
    const response = await api.put(`/export-data-connectors/${id}`, payload);
    return response.data || null;
  } catch (err) {
    const errorMsg = err?.response.data.error_msg || err.message;
    throw new Error(errorMsg);
  }
};

export const deleteExportDataConnector = async (id) => {
  try {
    const response = await api.delete(`/strapi/export-data-connectors/${id}`);
    return response.data || null;
  } catch (err) {
    const errorMsg = err?.response.data.error_msg || err.message;
    throw new Error(errorMsg);
  }
};

export const checkDataConnectorStatusAndPath = async (
  dataConnectorId,
  s3Path = "",
  operationMode = null
) => {
  const payload = {
    dataConnector: dataConnectorId,
  };

  if (operationMode === PROJECT_ACTIONS.import) {
    payload.importLocation = {
      path: s3Path,
    };
  } else if (operationMode === PROJECT_ACTIONS.export) {
    payload.exportDataConnectors = [
      {
        dataConnector: dataConnectorId,
        exportLocation: {
          path: s3Path,
        },
      },
    ];
  }

  const response = await api.post(
    `/data-connector-jobs/status`,
    { data: payload },
    { clearCacheEntry: true }
  );
  return response.data || null;
};
