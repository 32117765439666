import Box from "@mui/material/Box";

export const getHarmText = (val) => {
  let text = "";
  if (val < 0.25) {
    text = "Low";
  } else if (val < 0.5) {
    text = "Med";
  } else if (val < 0.8) {
    text = "High";
  } else {
    text = "Very High";
  }
  return text;
};

export default function HarmDot({ dotStyles, size = "100%" }) {
  return (
    <Box
      sx={{
        width: size,
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          ...(dotStyles || {})
        }}
      ></Box>
    </Box>
  );
}
