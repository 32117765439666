import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import globalMuiTheme from "./globalMuiTheme";
import { ContextProvider } from "./context/Context";
import * as serviceWorker from "./serviceWorker";
import { createBrowserHistory } from "history";
import { hotjar } from "react-hotjar";

import { Router } from "react-router-dom";

import LogRocket from "logrocket";
import mixpanel from "mixpanel-browser";
import { QueryClient, QueryCache, QueryClientProvider } from "react-query";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { Userpilot } from "userpilot";

import "styles/fontello.css";
import "react-dates/initialize";
import "styles/react-dates-custom.css";
import axios from "axios";

if (process.env.REACT_APP_HOTJAR_ID) {
  hotjar.initialize(
    process.env.REACT_APP_HOTJAR_ID, // hjid
    6 // hjsv
  );
}

if (process.env.REACT_APP_LOGROCKET_ID) {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_ID);
}

if (process.env.REACT_APP_MIXPANEL_TOKEN) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
}

Userpilot.initialize("NX-3e17e157");

export const environment = process.env.REACT_APP_ENV || "development";

export const history = createBrowserHistory({
  basename: process.env.REACT_APP_BRANCH_PATH,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      if (axios.isCancel(error)) return;
      console.error("Something went wrong", error);
    },
  }),
});

asyncWithLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCHDARKLY_ID,
}).then((LDProvider) => {
  const container = document.getElementById("root");
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={globalMuiTheme}>
          <CssBaseline />
          <ContextProvider>
            <Router
              basename={process.env.REACT_APP_BRANCH_PATH}
              history={history}
            >
              <QueryClientProvider client={queryClient}>
                <LDProvider>
                  <App />
                </LDProvider>
              </QueryClientProvider>
            </Router>
          </ContextProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.StrictMode>
  );
});
serviceWorker.unregister();
